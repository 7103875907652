import React from "react"
import { useRef } from "react"
import styled from "styled-components"
import { useState } from "react"

const NavigationBarContainer = styled.div`
  z-index: 1;
  background: white;
  position: sticky;
  top: 74px;
  display: flex;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 0.5rem;
  overflow: scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 1140px) {
    margin-left: 0;
    justify-content: center;
  }

  @media (min-width: 40em) {
    top: 70px;
    padding-top: 1rem;
  }
`

const NavigationBar = styled.nav`
  margin-inline: auto;
  display: flex;
  justify-content: center;
`

const MenuItem = styled.div`
  cursor: pointer;
  text-decoration: none;
  display: flex;
  color: var(--clr-text-blue);
  justify-content: center;
  align-items: center;

  font-family: var(--ff-secondary);
  font-style: normal;
  font-weight: 500;
  font-size: 1.125em;
  line-height: 20px;

  text-align: center;

  min-width: 131px;
  min-height: 50px;
  width: 131px;
  height: 50px;
  border-bottom: 4px solid var(--clr-primary);
  @media (min-width: 40em) {
    font-size: 1.5em;
    line-height: 32px;

    width: 190px;
    height: 90px;
    min-width: 190px;
    min-height: 90px;
    border-width: 8px;
  }

  &:hover {
    color: var(--clr-secondary-dark);
  }
`

const OurPeopleNavigationBar = ({ changeCategory }) => {
  const [active, setActive] = useState("all")
  const containerRef = useRef()

  const scrollToPosition = async index => {
    if (containerRef.current) {
      const menuItem = containerRef.current.children[0]?.children[index]
      if (menuItem) {
        const menuItemRect = menuItem.getBoundingClientRect()
        const scrollX = containerRef.current.scrollLeft + menuItemRect.left

        containerRef.current.scrollTo({
          top: 0,
          left:
            scrollX -
            containerRef.current.clientWidth / 2 +
            menuItemRect.width / 2,
          behavior: "smooth",
        })
      }
    }
  }

  const handleClick = async (filter, index, page) => {
    changeCategory(filter)
    scrollToPosition(index)
    setActive(page)
    const element = document.querySelector("#meet-our-people")
    if (element) {
      window.scrollTo({
        top: element.offsetTop - 74, // This scrolls to the top of the element
        behavior: "smooth",
      })
    }
  }

  const activeStyle = page => {
    if (active === page) {
      return {
        borderColor: "var(--clr-secondary-dark)",
        color: "var(--clr-secondary-dark)",
      }
    } else {
      return {}
    }
  }
  return (
    <NavigationBarContainer ref={containerRef}>
      <NavigationBar>
        <MenuItem
          onClick={() => handleClick(/./, 0, "all")}
          style={activeStyle("all")}
        >
          All
        </MenuItem>
        <MenuItem
          onClick={() =>
            handleClick(
              /^(?!.*Junior Software Developer).*Software.*|System/,
              1,
              "dev"
            )
          }
          style={activeStyle("dev")}
        >
          Software Engineering
        </MenuItem>
        <MenuItem
          onClick={() =>
            handleClick(
              /Cloud|DevOps|Senior Consultant|Solution Architect|Senior Test Engineer|Senior Test Automation Engineer|Test Automation Specialist|Senior Data Analyst/,
              2,
              "cloud"
            )
          }
          style={activeStyle("cloud")}
        >
          Cloud & Data
        </MenuItem>
        <MenuItem
          onClick={() =>
            handleClick(
              /^(?!.*Head of).*\b(Design|Senior UX Designer|Agile|Agility|Scrum|Digital Product Management|Lead Ontologist|Project)\b/i,
              3,
              "agile"
            )
          }
          style={activeStyle("agile")}
        >
          Agile & Design
        </MenuItem>
        <MenuItem
          onClick={() =>
            handleClick(
              /CEO|Lead Consultant|Head of Growth|Head of Sales|Communications and Marketing Specialist|Junior/,
              4,
              "growth"
            )
          }
          style={activeStyle("growth")}
        >
          Growth Enabling
        </MenuItem>
      </NavigationBar>
    </NavigationBarContainer>
  )
}

export default OurPeopleNavigationBar
